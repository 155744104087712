



























import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "./SpinnerComponent.vue";
import TargetsTableComponent from "./TargetsTableComponent.vue";
import ReportDataTableComponent from "./ReportDataTableComponent.vue";
import DiversityBreakdownComponent from "./DiversityBreakdownComponent.vue";
import EIRChartComponent from "./EIRChartComponent.vue";
import { ReportDataTable, EIRCrossAnalysis, ColumnStyleItem, EIRData, ReportDataTableRow } from "../store/models";
import { EmptyReportDataTable, EmptyReportDataTableRow } from "../store/models-empty";
import * as _ from "lodash";
import ReportHelper, { ReportType } from "./ReportHelper";
import { sum } from "lodash";
import { getEirColumnMapping } from "../lib/eir-column-mapping";

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
    DiversityBreakdownComponent,
    TargetsTableComponent,
    EIRChartComponent,
  },
})
export default class CCABDiversityComponent extends BaseComponent {
  @Prop() public reportHelper: any;
  @Prop() public reportMode: any;
  @Prop() public enabledFeatures: any;
  @Prop() public headlineText: any;
  @Prop() public yearlyDiversityData: any;
  // @Prop() public totalData: any;
  @Prop({ default: 1 }) public levelCutoff!: number;
  @Prop({ default: 1 }) public locationCutoff!: number;
  @Prop({ default: 1 }) public occupationCutoff!: number;

  private distributionByLevelTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private distributionByLocationTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private levelRetentionTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private locationRetentionTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private levelGrowthTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private locationGrowthTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private isLoaded: boolean = false;
  private chunkSize = 5;

  protected mounted() {
    console.log(this.yearlyDiversityData);
    this.refreshDistributionByLevelTable();
    this.refreshDistributionByLocationTable();
    this.refreshLevelRetentionTable();
    this.refreshLocationRetentionTable();
    this.refreshLevelGrowthTable();
    this.refreshLocationGrowthTable();
    this.isLoaded = true;
  }

  private refreshDistributionByLevelTable() {
    this.distributionByLevelTable.parentsBold = false;
    this.distributionByLevelTable.title = "By Level";
    this.createHeadcountDistributionTable(this.distributionByLevelTable, this.yearlyDiversityData, "level");
  }

  private refreshDistributionByLocationTable() {
    this.distributionByLocationTable.parentsBold = false;
    this.distributionByLocationTable.title = "By Location";
    this.createHeadcountDistributionTable(this.distributionByLocationTable, this.yearlyDiversityData, "location", false);
  }

  private refreshLevelRetentionTable() {
    this.levelRetentionTable.parentsBold = false;
    this.levelRetentionTable.title = "By Occupation";
    this.createRetentionTable(this.levelRetentionTable, this.yearlyDiversityData, "level");
  }

  private refreshLocationRetentionTable() {
    this.locationRetentionTable.parentsBold = false;
    this.locationRetentionTable.title = "By Location";
    this.createRetentionTable(this.locationRetentionTable, this.yearlyDiversityData, "location");
  }

  private refreshLevelGrowthTable() {
    this.levelGrowthTable.parentsBold = false;
    this.levelGrowthTable.title = "By Level";
    this.createGrowthTable(this.levelGrowthTable, this.yearlyDiversityData, "level");
  }

  private refreshLocationGrowthTable() {
    this.locationGrowthTable.parentsBold = false;
    this.locationGrowthTable.title = "By Location";
    this.createGrowthTable(this.locationGrowthTable, this.yearlyDiversityData, "location");
  }

  private createHeadcountDistributionTable(table: ReportDataTable, yearlyData: any, field: string, totalRow = true) {
    table.className = "margin-bottom";
    table.headers = [{ text: "", rowLayout: 1, align: "left" }] as any[];
    table.parentHeaders = [{ text: "", rowLayout: 1, align: "left" }] as any[];
    table.totalRow = _.cloneDeep(EmptyReportDataTableRow);

    const rowDatas: Record<string, Record<string, string[]>> = {};
    for (const year of Object.keys(yearlyData).sort()) {
      table.parentHeaders = table.parentHeaders.concat([{ text: year, rowLayout: 2, align: "left" }] as any[]);
      const data = this.yearlyDiversityData[year][field] as EIRData;

      if (data) {
        const headerData = this.getHeaders(data);
        const headers = headerData.headers;
        const headerValues = headerData.headerValues;
        table.headers = table.headers.concat(headers);

        const totalRowData = new Array(headerValues.length).fill(0);
        let denominator = 0;
        for (const row of data.totals.employeeAnalysis) {
          let rowDenominator = 0;
          const rowData = new Array(headerValues.length).fill("-");
          const rowName = row._id.row ? row._id.row : "Not Specified";

          for (const col of row.columns) {
            const columnName = col.column_value ? col.column_value : "Not Specified";
            let colIndex = -1;
            switch (columnName) {
              case "no":
              case "unknown":
                colIndex = 1;
                break;
              default:
                colIndex = 0;
            }

            if (colIndex > -1) {
              if (rowData[colIndex] === "-") {
                rowData[colIndex] = 0;
              }
              const value = col.employeeAnalysis.endDateSize * 100;
              rowDenominator += col.employeeAnalysis.endDateSize;
              rowData[colIndex] += value;
              totalRowData[colIndex] += value;
            }
          }
          denominator += rowDenominator;

          if (!rowDatas[rowName]) {
            rowDatas[rowName] = {};
          }
          rowDatas[rowName][year] = rowData.map((value) => {
            if (typeof value === "string") {
              return value;
            }
            return `${(value / rowDenominator).toFixed(1)}%`;
          });
        }
        table.totalRow.data = table.totalRow.data.concat(totalRowData.map((value) => `${(value / denominator).toFixed(1)}%`));
      }
    }
    for (const key of Object.keys(rowDatas)) {
      const rowData: string[] = [];
      for (const year of Object.keys(this.yearlyDiversityData).sort()) {
        if (rowDatas[key][year]) {
          rowData.push(...rowDatas[key][year]);
        } else {
          rowData.push("-", "-");
        }
      }
      table.rows.push({
        data: [key].concat(rowData),
        children: [],
      });
      if (!totalRow) {
        table.totalRow = null;
      }
    }
  }

  private createRetentionTable(table: ReportDataTable, yearlyData: any, field: string) {
    table.className = "margin-bottom";
    table.headers = [{ text: "", rowLayout: 1, align: "left" }] as any[];
    table.parentHeaders = [{ text: "", rowLayout: 1, align: "left" }] as any[];
    table.totalRow = _.cloneDeep(EmptyReportDataTableRow);

    const rowDatas: Record<string, Record<string, number[]>> = {};
    for (const year of Object.keys(yearlyData).sort()) {
      table.parentHeaders = table.parentHeaders.concat([{ text: year, rowLayout: 2, align: "left" }] as any[]);
      const data = this.yearlyDiversityData[year][field] as EIRData;

      if (data) {
        const headerData = this.getHeaders(data);
        const headers = headerData.headers;
        const headerValues = headerData.headerValues;
        table.headers = table.headers.concat(headers);

        for (const row of data.totals.employeeAnalysis) {
          const rowData = new Array(headerValues.length).fill("-");
          const rowName = row._id.row ? row._id.row : "Not Specified";
          let indigenousDenominator = 0;
          let nonIndigenousDenominator = 0;

          for (const col of row.columns) {
            const columnName = col.column_value ? col.column_value : "Not Specified";
            let colIndex = -1;
            switch (columnName) {
              case "no":
              case "unknown":
                colIndex = 1;
                nonIndigenousDenominator += 1;
                break;
              default:
                colIndex = 0;
                indigenousDenominator += 1;
            }

            if (col.employeeAnalysis.startDateSize === 0) {
              continue;
            }

            if (colIndex > -1) {
              if (rowData[colIndex] === "-") {
                rowData[colIndex] = 0;
              }
              const value = col.employeeAnalysis.turnoverPercentage * 100;
              rowData[colIndex] += value;
            }
          }

          if (rowData[0] !== "-") {
            rowData[0] = rowData[0] / indigenousDenominator;
          }
          if (rowData[1] !== "-") {
            rowData[1] = rowData[1] / nonIndigenousDenominator;
          }

          if (!rowDatas[rowName]) {
            rowDatas[rowName] = {};
          }
          rowDatas[rowName][year] = rowData;
        }
      }
    }
    for (const key of Object.keys(rowDatas)) {
      const rowData: Array<string | number> = [];
      for (const year of Object.keys(this.yearlyDiversityData).sort()) {
        if (rowDatas[key][year]) {
          rowData.push(...rowDatas[key][year]);
        } else {
          rowData.push("-", "-");
        }
      }
      const colStyles: ColumnStyleItem[] = [];
      rowData.forEach((value, index) => {
        if (typeof value === "number" && value < 100) {
          colStyles.push({ index: index + 1, textColour: "#CD4158" });
        }
      });
      table.rows.push({
        data: [key].concat(
          rowData.map((value) => {
            if (typeof value === "string") {
              return value;
            }
            return `${value.toFixed(1)}%`;
          }),
        ),
        children: [],
        columnStyle: colStyles,
      });
    }
  }

  private createGrowthTable(table: ReportDataTable, yearlyData: any, field: string) {
    table.className = "margin-bottom";
    table.headers = [{ text: "", rowLayout: 1, align: "left" }] as any[];
    table.parentHeaders = [{ text: "", rowLayout: 1, align: "left" }] as any[];
    table.totalRow = _.cloneDeep(EmptyReportDataTableRow);

    const rowDatas: Record<string, Record<string, number[]>> = {};
    for (const year of Object.keys(yearlyData).sort()) {
      table.parentHeaders = table.parentHeaders.concat([{ text: year, rowLayout: 2, align: "left" }] as any[]);
      const data = this.yearlyDiversityData[year][field] as EIRData;

      if (data) {
        const headerData = this.getHeaders(data);
        const headers = headerData.headers;
        const headerValues = headerData.headerValues;
        table.headers = table.headers.concat(headers);

        for (const row of data.totals.employeeAnalysis) {
          const rowData = new Array(headerValues.length).fill("-");
          const rowName = row._id.row ? row._id.row : "Not Specified";
          let indigenousDenominator = 0;
          let nonIndigenousDenominator = 0;

          for (const col of row.columns) {
            const columnName = col.column_value ? col.column_value : "Not Specified";
            let colIndex = -1;
            switch (columnName) {
              case "no":
              case "unknown":
                colIndex = 1;
                nonIndigenousDenominator += 1;
                break;
              default:
                colIndex = 0;
                indigenousDenominator += 1;
            }

            if (col.employeeAnalysis.startDateSize === 0) {
              continue;
            }

            if (colIndex > -1) {
              if (rowData[colIndex] === "-") {
                rowData[colIndex] = 0;
              }
              const value = ((col.employeeAnalysis.endDateSize - col.employeeAnalysis.startDateSize) / col.employeeAnalysis.startDateSize) * 100;
              rowData[colIndex] += value;
            }
          }

          if (rowData[0] !== "-") {
            rowData[0] = rowData[0] / indigenousDenominator;
          }
          if (rowData[1] !== "-") {
            rowData[1] = rowData[1] / nonIndigenousDenominator;
          }

          if (!rowDatas[rowName]) {
            rowDatas[rowName] = {};
          }
          rowDatas[rowName][year] = rowData;
        }
      }
    }
    for (const key of Object.keys(rowDatas)) {
      const rowData: Array<string | number> = [];
      for (const year of Object.keys(this.yearlyDiversityData).sort()) {
        if (rowDatas[key][year]) {
          rowData.push(...rowDatas[key][year]);
        } else {
          rowData.push("-", "-");
        }
      }
      const colStyles: ColumnStyleItem[] = [];
      rowData.forEach((value, index) => {
        if (typeof value === "number" && value !== 0) {
          const color = value > 0 ? "#63B6AB" : "#CD4158";
          colStyles.push({ index: index + 1, textColour: color });
        }
      });
      table.rows.push({
        data: [key].concat(
          rowData.map((value) => {
            if (typeof value === "string") {
              return value;
            }
            return `${value.toFixed(1)}%`;
          }),
        ),
        children: [],
        columnStyle: colStyles,
      });
    }
  }

  private getHeaders(data: EIRData): { headers: any; headerValues: string[] } {
    const headers: any[] = [];
    const headerValues: string[] = [];

    headerValues.push("Indigenous", "Non-Indigenous");
    headers.push(
      {
        text: "Indigenous",
        rowLayout: 1,
        align: "left",
      },
      {
        text: "Non-Indigenous",
        rowLayout: 1,
        align: "left",
      },
    );

    return { headers, headerValues };
  }

  private toTitleCase(str: string, column: string): string {
    const mapping = getEirColumnMapping();
    const columnMapping = column.split(".").pop();

    if (columnMapping && mapping[columnMapping] && mapping[columnMapping][str]) {
      return mapping[columnMapping][str];
    }

    switch (str) {
      case "m\u00e9tis":
        return "M\u00e9tis";
      case "bachelor's degree":
        return "Bachelor's Degree";
      case "master\u75f4 degree":
        return "Master\u2019s Degree";
      case "trade/technical/vocational training":
        return "Trade / Technical / Vocational Training";
      case "i'd prefer not to answer":
        return "I'd Prefer Not To Answer";
      default:
        str = str.replaceAll("_", " ");
        return str.replace(/\w*/g, (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
  }

  private rowVarianceFilter(value: EIRCrossAnalysis) {
    if (value.columns.length < 2) {
      return false;
    }
    const maxValue = Math.max(...value.columns.map((col) => col.average_hourly_rate));
    const minValue = Math.min(...value.columns.map((col) => col.average_hourly_rate));
    const variance = (maxValue - minValue) / maxValue;
    if (variance < 0.05) {
      return false;
    }
    return true;
  }
}
