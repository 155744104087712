







































import { Component, Vue, Prop } from "vue-property-decorator";
import SimpleChartComponent from "./SimpleChartComponent.vue";
import * as colors from "../lib/colors";
import * as _ from "lodash";
import { getDonut } from "@/lib/charts";
import { DonutMeta, EIRData } from "@/store/models";
import { EmptyDonutMeta } from "@/store/models-empty";
import { getEirColumnMapping } from "@/lib/eir-column-mapping";

@Component({
  components: {
    SimpleChartComponent,
  },
})
export default class EIRChartComponent extends Vue {
  @Prop() public totalData: any;
  @Prop({ default: 1 }) public n!: number;
  @Prop({ default: "" }) public question!: string;

  private donutChartData: any = {};
  private parsedData: Array<{ name: string; value: number; color: string }> = [];
  private rows: Array<Array<{ name: string; value: number; color: string }>> = [];
  private chunkSize = 5;

  get showChart(): boolean {
    return this.totalData.totals.cross_analysis.length > 0;
  }

  private mounted() {
    this.parseEIRData();
    this.getDonut();
  }

  private getDonut() {
    const donutMeta: DonutMeta = _.cloneDeep(EmptyDonutMeta);
    donutMeta.bgColor = "transparent";
    donutMeta.legendEnabled = false;
    donutMeta.formatNumbersToCurrency = false;
    donutMeta.exportingEnabled = false;
    const series: any[] = [
      {
        name: "Percent Distribution by Headcount",
        innerSize: "65%",
        data: this.parsedData.map((entry) => {
          return {
            name: entry.name,
            y: Math.round(entry.value * 10) / 10,
          };
        }),
      },
    ];
    this.donutChartData = this.donutChartData = getDonut(series, donutMeta, false);
  }

  private parseEIRData() {
    if (this.totalData) {
      const data = this.totalData as EIRData;
      const denominator = data.totals.headcount[0].total;

      const row = data.totals.cross_analysis[0];
      row.columns.forEach((col, index) => {
        const columnName = col.column_value ? col.column_value : "Not Specified";
        this.parsedData.push({
          name: this.toTitleCase(columnName, data.reportMeta.column),
          value: (col.codes.length / denominator) * 100,
          color: this.getColor(index),
        });
      });

      this.parsedData.sort((a, b) => a.name.localeCompare(b.name));
      this.parsedData.forEach((item, index) => {
        item.color = this.getColor(index);
      });
      for (let i = 0; i < this.parsedData.length; i += this.chunkSize) {
        const chunk = this.parsedData.slice(i, i + this.chunkSize);
        this.rows.push(chunk);
      }
    }
  }

  private toTitleCase(str: string, column: string): string {
    const mapping = getEirColumnMapping();
    const columnMapping = column.split(".").pop();

    if (columnMapping && mapping[columnMapping] && mapping[columnMapping][str]) {
      return mapping[columnMapping][str];
    }

    switch (str) {
      case "m\u00e9tis":
        return "M\u00e9tis";
      case "bachelor's degree":
        return "Bachelor's Degree";
      case "master\u75f4 degree":
        return "Master\u2019s Degree";
      case "trade/technical/vocational training":
        return "Trade / Technical / Vocational Training";
      case "i'd prefer not to answer":
        return "I'd Prefer not to answer";
      default:
        str = str.replaceAll("_", " ");
        return str.replace(/\w*/g, (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
  }

  private getColor(index: number): string {
    const constrainedIndex = index % 15;
    return colors.colors[constrainedIndex];
  }
}
