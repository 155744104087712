


































import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "./SpinnerComponent.vue";
import TargetsTableComponent from "./TargetsTableComponent.vue";
import ReportDataTableComponent from "./ReportDataTableComponent.vue";
import DiversityBreakdownComponent from "./DiversityBreakdownComponent.vue";
import EIRChartComponent from "./EIRChartComponent.vue";
import { ReportDataTable, EIRCrossAnalysis, ColumnStyleItem, EIRData } from "../store/models";
import { EmptyReportDataTable, EmptyReportDataTableRow } from "../store/models-empty";
import * as _ from "lodash";
import ReportHelper, { ReportType } from "./ReportHelper";
import { sum } from "lodash";
import { getEirColumnMapping } from "../lib/eir-column-mapping";

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
    DiversityBreakdownComponent,
    TargetsTableComponent,
    EIRChartComponent,
  },
})
export default class EIRDistributionComponent extends BaseComponent {
  @Prop() public reportHelper: any;
  @Prop() public reportMode: any;
  @Prop() public enabledFeatures: any;
  @Prop() public headlineText: any;
  @Prop() public levelData: any;
  @Prop() public locationData: any;
  @Prop() public hourlyOccupationData: any;
  @Prop() public totalData: any;
  @Prop({ default: "" }) public question!: string;
  @Prop({ default: 1 }) public levelCutoff!: number;
  @Prop({ default: 1 }) public locationCutoff!: number;
  @Prop({ default: 1 }) public occupationCutoff!: number;

  private distributionByLevelTables: ReportDataTable[] = [];
  private distributionByLocationTables: ReportDataTable[] = [];
  private averageRateTables: ReportDataTable[] = [];
  private employmentHoursTables: ReportDataTable[] = [];
  private occupationRetentionTables: ReportDataTable[] = [];
  private locationRetentionTables: ReportDataTable[] = [];
  private occupationGrowthTables: ReportDataTable[] = [];
  private locationGrowthTables: ReportDataTable[] = [];
  private isLoaded: boolean = false;
  private chunkSize = 5;

  protected mounted() {
    this.refreshDistributionByLevelTable();
    this.refreshDistributionByLocationTable();
    this.refreshAverageRateTable();
    this.refreshEmploymentHoursTable();
    this.refreshOccupationRetentionTables();
    this.refreshLocationRetentionTables();
    this.refreshOccupationGrowthTables();
    this.refreshLocationGrowthTables();
    this.isLoaded = true;
  }

  private refreshDistributionByLevelTable() {
    if (this.levelData) {
      const levelData = this.levelData as EIRData;
      const headerData = this.getHeaders(levelData);

      for (let i = 0; i < headerData.headers.length; i += this.chunkSize) {
        const headers = headerData.headers.slice(i, i + this.chunkSize);
        const headerValues = headerData.headerValues.slice(i, i + this.chunkSize);
        const table = _.cloneDeep(EmptyReportDataTable);

        table.parentsBold = false;
        table.title = "By Level";
        table.className = "margin-bottom";
        table.totalRow = null;

        const tableHeaders: any[] = [{ text: "", rowLayout: 1, align: "left" }].concat(headers);
        table.headers = tableHeaders;

        for (const row of levelData.totals.cross_analysis) {
          const denominator = sum(row.columns.map((col) => col.codes.length));
          const rowData = new Array(headerValues.length).fill("-");

          for (const col of row.columns) {
            const columnValue = col.column_value ? col.column_value : "Not Specified";
            const columnName = this.toTitleCase(columnValue, levelData.reportMeta.column);
            const colIndex = headerValues.indexOf(columnName);

            if (colIndex > -1 && denominator >= this.levelCutoff) {
              const value = (col.codes.length / denominator) * 100;
              rowData[colIndex] = `${value.toFixed(1)}%`;
            }
          }
          table.rows.push({
            data: [row._id.row ? row._id.row : "Not Specified"].concat(rowData),
            children: [],
          });
        }
        this.distributionByLevelTables.push(table);
      }
    }
  }

  private refreshDistributionByLocationTable() {
    if (this.locationData) {
      const locationData = this.locationData as EIRData;
      const headerData = this.getHeaders(locationData);

      for (let i = 0; i < headerData.headers.length; i += this.chunkSize) {
        const headers = headerData.headers.slice(i, i + this.chunkSize);
        const headerValues = headerData.headerValues.slice(i, i + this.chunkSize);
        const table = _.cloneDeep(EmptyReportDataTable);
        table.totalRow = null;

        table.parentsBold = false;
        table.title = "By Location";
        table.className = "margin-bottom";

        const tableHeaders: any[] = [{ text: "", rowLayout: 1, align: "left" }].concat(headers);
        table.headers = tableHeaders;

        for (const row of locationData.totals.cross_analysis) {
          const denominator = sum(row.columns.map((col) => col.codes.length));
          const rowData = new Array(headerValues.length).fill("-");

          for (const col of row.columns) {
            const columnValue = col.column_value ? col.column_value : "Not Specified";
            const columnName = this.toTitleCase(columnValue, locationData.reportMeta.column);
            const colIndex = headerValues.indexOf(columnName);

            if (colIndex > -1 && denominator >= this.locationCutoff) {
              const value = ((col.codes.length / denominator) * 100).toFixed(1);
              rowData[colIndex] = `${value}%`;
            }
          }
          table.rows.push({
            data: [row._id.row ? row._id.row : "Not Specified"].concat(rowData),
            children: [],
          });
        }
        this.distributionByLocationTables.push(table);
      }
    }
  }

  private refreshAverageRateTable() {
    if (this.hourlyOccupationData) {
      const occupationData = this.hourlyOccupationData as EIRData;
      const headerData = this.getHeaders(occupationData);
      const totalRowSums = new Array(occupationData.totals.cross_analysis.filter(this.rowVarianceFilter).length).fill(0);
      const totalRowCounts = new Array(occupationData.totals.cross_analysis.filter(this.rowVarianceFilter).length).fill(0);

      for (let i = 0; i < headerData.headers.length; i += this.chunkSize) {
        const headers = headerData.headers.slice(i, i + this.chunkSize);
        const headerValues = headerData.headerValues.slice(i, i + this.chunkSize);
        const table = _.cloneDeep(EmptyReportDataTable);
        const lastTable = i >= headerData.headers.length - this.chunkSize;

        table.parentsBold = false;
        table.title = "Average Rate";
        table.className = "margin-bottom";
        table.totalText = "AVERAGE";

        const tableHeaders: any[] = [{ text: "", rowLayout: 1, align: "left" }].concat(headers);
        if (lastTable) {
          table.headers = tableHeaders.concat([{ text: "Average", rowLayout: 1, align: "left" }]);
        } else {
          table.headers = tableHeaders;
        }

        let totalRowData: number[];
        let rowCount: number[];
        if (lastTable) {
          totalRowData = new Array(headerValues.length + 1).fill(0);
          rowCount = new Array(headerValues.length + 1).fill(0);
        } else {
          totalRowData = new Array(headerValues.length).fill(0);
          rowCount = new Array(headerValues.length).fill(0);
        }

        for (const { row, rowIndex } of occupationData.totals.cross_analysis.filter(this.rowVarianceFilter).map((item, index) => ({ row: item, rowIndex: index }))) {
          let rowData: string[];
          if (lastTable) {
            rowData = new Array(headerValues.length + 1).fill("-");
          } else {
            rowData = new Array(headerValues.length).fill("-");
          }

          let rowTotal = 0;
          let count = 0;
          for (const col of row.columns) {
            const columnValue = col.column_value ? col.column_value : "Not Specified";
            const columnName = this.toTitleCase(columnValue, occupationData.reportMeta.column);
            const colIndex = headerValues.indexOf(columnName);

            if (colIndex > -1) {
              const value = col.average_hourly_rate;
              rowData[colIndex] = "$" + value.toFixed(2);

              totalRowData[colIndex] += value;
              rowCount[colIndex] += 1;

              rowTotal += value;
              count += 1;

              totalRowSums[rowIndex] += value;
              totalRowCounts[rowIndex] += 1;
            }
          }

          // Create total average colummn
          if (lastTable) {
            let average = 0;
            if (count > 0) {
              average = totalRowSums[rowIndex] / totalRowCounts[rowIndex];
            }
            rowData[headerValues.length] = "$" + average.toFixed(2);
          }

          table.rows.push({
            data: [row._id.row ? row._id.row : "Not Specified"].concat(rowData),
            children: [],
          });
        }
        table.totalRow = _.cloneDeep(EmptyReportDataTableRow);
        table.totalRow.data = totalRowData.map((value, index) => {
          if (index === headerValues.length) {
            return "$" + (sum(totalRowSums) / sum(totalRowCounts)).toFixed(2);
          }
          if (rowCount[index] === 0) {
            return "$0.00";
          }
          return "$" + (value / rowCount[index]).toFixed(2);
        });
        this.averageRateTables.push(table);
      }
    }
  }

  private refreshEmploymentHoursTable() {
    if (this.hourlyOccupationData) {
      const occupationData = this.hourlyOccupationData as EIRData;
      const headerData = this.getHeaders(occupationData);
      const totalRowSums = new Array(occupationData.totals.cross_analysis.filter(this.rowVarianceFilter).length).fill(0);

      for (let i = 0; i < headerData.headers.length; i += this.chunkSize) {
        const headers = headerData.headers.slice(i, i + this.chunkSize);
        const headerValues = headerData.headerValues.slice(i, i + this.chunkSize);
        const table = _.cloneDeep(EmptyReportDataTable);
        const lastTable = i >= headerData.headers.length - this.chunkSize;

        table.parentsBold = false;
        table.title = "Employment Hours";
        table.className = "margin-bottom";

        const tableHeaders: any[] = [{ text: "", rowLayout: 1, align: "left" }].concat(headers);
        if (lastTable) {
          table.headers = tableHeaders.concat([{ text: "Total", rowLayout: 1, align: "left" }]);
        } else {
          table.headers = tableHeaders;
        }

        let totalRowData: number[];
        if (lastTable) {
          totalRowData = new Array(headerValues.length + 1).fill(0);
        } else {
          totalRowData = new Array(headerValues.length).fill(0);
        }
        for (const { row, rowIndex } of occupationData.totals.cross_analysis.filter(this.rowVarianceFilter).map((item, index) => ({ row: item, rowIndex: index }))) {
          let rowData: any[];
          if (lastTable) {
            rowData = new Array(headerValues.length + 1).fill("-");
            rowData[headerValues.length] = 0;
          } else {
            rowData = new Array(headerValues.length).fill("-");
          }

          for (const col of row.columns) {
            const columnValue = col.column_value ? col.column_value : "Not Specified";
            const columnName = this.toTitleCase(columnValue, occupationData.reportMeta.column);
            const colIndex = headerValues.indexOf(columnName);

            if (colIndex > -1) {
              const value = col.total_employment_hours;
              rowData[colIndex] = value.toFixed(0);
              totalRowSums[rowIndex] += value;
              totalRowData[colIndex] += value;
            }
          }

          if (lastTable) {
            rowData[headerValues.length] = totalRowSums[rowIndex].toFixed(0);
          }
          table.rows.push({
            data: [row._id.row ? row._id.row : "Not Specified"].concat(rowData),
            children: [],
          });
        }

        table.totalRow = _.cloneDeep(EmptyReportDataTableRow);
        table.totalRow.data = totalRowData.map((value, index) => {
          if (index === headerValues.length) {
            return sum(totalRowSums).toFixed(0);
          }
          return value.toFixed(0);
        });
        this.employmentHoursTables.push(table);
      }
    }
  }

  private refreshOccupationRetentionTables() {
    if (this.levelData) {
      const occupationData = this.levelData as EIRData;
      const headerData = this.getHeaders(occupationData);

      for (let i = 0; i < headerData.headers.length; i += this.chunkSize) {
        const headers = headerData.headers.slice(i, i + this.chunkSize);
        const headerValues = headerData.headerValues.slice(i, i + this.chunkSize);
        const table = _.cloneDeep(EmptyReportDataTable);
        table.totalRow = null;

        table.parentsBold = false;
        table.title = "Level";
        table.className = "margin-bottom";

        const tableHeaders: any[] = [{ text: "", rowLayout: 1, align: "left" }].concat(headers);
        table.headers = tableHeaders;

        for (const row of occupationData.totals.employeeAnalysis) {
          const rowData = new Array(headerValues.length).fill("-");
          const colStyles: ColumnStyleItem[] = [];

          for (const col of row.columns) {
            let columnValue: string;
            if (Array.isArray(col.column_value) && col.column_value.length > 0) {
              columnValue = col.column_value ? col.column_value[0] : "Not Specified";
            } else {
              columnValue = col.column_value ? col.column_value : "Not Specified";
            }
            const columnName = this.toTitleCase(columnValue, occupationData.reportMeta.column);
            const colIndex = headerValues.indexOf(columnName);

            if (colIndex > -1 && col.employeeAnalysis.startDateSize >= this.occupationCutoff && col.employeeAnalysis.endDateSize >= this.occupationCutoff) {
              const value = (col.employeeAnalysis.turnoverPercentage * 100).toFixed(0);
              rowData[colIndex] = `${value}%`;

              if (col.employeeAnalysis.turnoverPercentage !== 0) {
                const color = col.employeeAnalysis.turnoverPercentage >= 1 ? "" : "#CD4158";
                colStyles.push({ index: colIndex + 1, textColour: color });
              }
            }
          }
          table.rows.push({
            data: [row._id.row ? row._id.row : "Not Specified"].concat(rowData),
            children: [],
            columnStyle: colStyles,
          });
        }
        this.occupationRetentionTables.push(table);
      }
    }
  }

  private refreshLocationRetentionTables() {
    if (this.locationData) {
      const locationData = this.locationData as EIRData;
      const headerData = this.getHeaders(locationData);

      for (let i = 0; i < headerData.headers.length; i += this.chunkSize) {
        const headers = headerData.headers.slice(i, i + this.chunkSize);
        const headerValues = headerData.headerValues.slice(i, i + this.chunkSize);
        const table = _.cloneDeep(EmptyReportDataTable);
        table.totalRow = null;

        table.parentsBold = false;
        table.title = "Location";
        table.className = "margin-bottom";

        const tableHeaders: any[] = [{ text: "", rowLayout: 1, align: "left" }].concat(headers);
        table.headers = tableHeaders;

        for (const row of locationData.totals.employeeAnalysis) {
          const rowData = new Array(headerValues.length).fill("-");
          const colStyles: ColumnStyleItem[] = [];

          for (const col of row.columns) {
            let columnValue: string;
            if (Array.isArray(col.column_value) && col.column_value.length > 0) {
              columnValue = col.column_value ? col.column_value[0] : "Not Specified";
            } else {
              columnValue = col.column_value ? col.column_value : "Not Specified";
            }
            const columnName = this.toTitleCase(columnValue, locationData.reportMeta.column);
            const colIndex = headerValues.indexOf(columnName);

            if (colIndex > -1 && col.employeeAnalysis.startDateSize >= this.levelCutoff && col.employeeAnalysis.endDateSize >= this.levelCutoff) {
              const value = (col.employeeAnalysis.turnoverPercentage * 100).toFixed(0);
              rowData[colIndex] = `${value}%`;

              if (col.employeeAnalysis.turnoverPercentage !== 0) {
                const color = col.employeeAnalysis.turnoverPercentage >= 1 ? "" : "#CD4158";
                colStyles.push({ index: colIndex + 1, textColour: color });
              }
            }
          }
          table.rows.push({
            data: [row._id.row ? row._id.row : "Not Specified"].concat(rowData),
            children: [],
            columnStyle: colStyles,
          });
        }
        this.locationRetentionTables.push(table);
      }
    }
  }

  private refreshOccupationGrowthTables() {
    if (this.levelData) {
      const occupationData = this.levelData as EIRData;
      const headerData = this.getHeaders(occupationData);

      for (let i = 0; i < headerData.headers.length; i += this.chunkSize) {
        const headers = headerData.headers.slice(i, i + this.chunkSize);
        const headerValues = headerData.headerValues.slice(i, i + this.chunkSize);
        const table = _.cloneDeep(EmptyReportDataTable);
        table.totalRow = null;

        table.parentsBold = false;
        table.title = "Level";
        table.className = "margin-bottom";

        const tableHeaders: any[] = [{ text: "", rowLayout: 1, align: "left" }].concat(headers);
        table.headers = tableHeaders;

        for (const row of occupationData.totals.employeeAnalysis) {
          const rowData = new Array(headerValues.length).fill("-");
          const colStyles: ColumnStyleItem[] = [];

          for (const col of row.columns) {
            let columnValue: string;
            if (Array.isArray(col.column_value) && col.column_value.length > 0) {
              columnValue = col.column_value ? col.column_value[0] : "Not Specified";
            } else {
              columnValue = col.column_value ? col.column_value : "Not Specified";
            }
            const columnName = this.toTitleCase(columnValue, occupationData.reportMeta.column);
            const colIndex = headerValues.indexOf(columnName);

            if (colIndex > -1 && col.employeeAnalysis.startDateSize >= this.occupationCutoff && col.employeeAnalysis.endDateSize >= this.occupationCutoff) {
              const value = (col.employeeAnalysis.growthPercentage * 100).toFixed(0);
              rowData[colIndex] = `${value}%`;

              if (col.employeeAnalysis.growthPercentage !== 0) {
                const color = col.employeeAnalysis.growthPercentage > 0 ? "#63B6AB" : "#CD4158";
                colStyles.push({ index: colIndex + 1, textColour: color });
              }
            }
          }
          table.rows.push({
            data: [row._id.row ? row._id.row : "Not Specified"].concat(rowData),
            children: [],
            columnStyle: colStyles,
          });
        }
        this.occupationGrowthTables.push(table);
      }
    }
  }

  private refreshLocationGrowthTables() {
    if (this.locationData) {
      const locationData = this.locationData as EIRData;
      const headerData = this.getHeaders(locationData);

      for (let i = 0; i < headerData.headers.length; i += this.chunkSize) {
        const headers = headerData.headers.slice(i, i + this.chunkSize);
        const headerValues = headerData.headerValues.slice(i, i + this.chunkSize);
        const table = _.cloneDeep(EmptyReportDataTable);
        table.totalRow = null;

        table.parentsBold = false;
        table.title = "Location";
        table.className = "margin-bottom";

        const tableHeaders: any[] = [{ text: "", rowLayout: 1, align: "left" }].concat(headers);
        table.headers = tableHeaders;

        for (const row of locationData.totals.employeeAnalysis) {
          const rowData = new Array(headerValues.length).fill("-");
          const colStyles: ColumnStyleItem[] = [];

          for (const col of row.columns) {
            let columnValue: string;
            if (Array.isArray(col.column_value) && col.column_value.length > 0) {
              columnValue = col.column_value ? col.column_value[0] : "Not Specified";
            } else {
              columnValue = col.column_value ? col.column_value : "Not Specified";
            }
            const columnName = this.toTitleCase(columnValue, locationData.reportMeta.column);
            const colIndex = headerValues.indexOf(columnName);

            if (colIndex > -1 && col.employeeAnalysis.startDateSize >= this.levelCutoff && col.employeeAnalysis.endDateSize >= this.levelCutoff) {
              const value = (col.employeeAnalysis.growthPercentage * 100).toFixed(0);
              rowData[colIndex] = `${value}%`;

              if (col.employeeAnalysis.growthPercentage !== 0) {
                const color = col.employeeAnalysis.growthPercentage > 0 ? "#63B6AB" : "#CD4158";
                colStyles.push({ index: colIndex + 1, textColour: color });
              }
            }
          }
          table.rows.push({
            data: [row._id.row ? row._id.row : "Not Specified"].concat(rowData),
            children: [],
            columnStyle: colStyles,
          });
        }
        this.locationGrowthTables.push(table);
      }
    }
  }

  private getHeaders(data: EIRData): { headers: any; headerValues: string[] } {
    const headers: any[] = [];
    const headerValues: string[] = [];

    for (const row of data.totals.cross_analysis) {
      for (const col of row.columns) {
        const columnValue = col.column_value ? col.column_value : "Not Specified";
        const columnName = this.toTitleCase(columnValue, data.reportMeta.column);

        if (!headerValues.includes(columnName)) {
          headerValues.push(columnName);
          headers.push({
            text: columnName,
            rowLayout: 1,
            align: "left",
          });
        }
      }
    }

    headers.sort((a, b) => this.sortFunction(a.text, b.text));
    headerValues.sort((a, b) => this.sortFunction(a, b));

    return { headers, headerValues };
  }

  private toTitleCase(str: string, column: string): string {
    const mapping = getEirColumnMapping();
    const columnMapping = column.split(".").pop();

    if (columnMapping && mapping[columnMapping] && mapping[columnMapping][str]) {
      return mapping[columnMapping][str];
    }

    switch (str) {
      case "m\u00e9tis":
        return "M\u00e9tis";
      case "bachelor's degree":
        return "Bachelor's Degree";
      case "master\u75f4 degree":
        return "Master\u2019s Degree";
      case "trade/technical/vocational training":
        return "Trade / Technical / Vocational Training";
      case "i'd prefer not to answer":
        return "I'd Prefer Not To Answer";
      default:
        str = str.replaceAll("_", " ");
        return str.replace(/\w*/g, (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
  }

  private rowVarianceFilter(value: EIRCrossAnalysis) {
    if (value.columns.length < 2) {
      return false;
    }
    const maxValue = Math.max(...value.columns.map((col) => col.average_hourly_rate));
    const minValue = Math.min(...value.columns.map((col) => col.average_hourly_rate));
    const variance = (maxValue - minValue) / maxValue;
    if (variance < 0.05) {
      return false;
    }
    return true;
  }

  private sortFunction(a: string, b: string): number {
    const customColumns = ["No", "Yes", "I'd prefer not to answer"];
    if (customColumns.indexOf(a) > -1 && customColumns.indexOf(b) > -1) {
      return customColumns.indexOf(a) - customColumns.indexOf(b);
    }

    return a.localeCompare(b);
  }
}
